<template>
  <v-dialog
    persistent
    v-model="active"
    :width="screenWidth + '%'"
  >
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <p class="text-title mon-bold">{{ texts.rejectorder.title }}</p>
      <div class="display-flex align-items-center justify-content-center">
        <img
          width="80px"
          src="@/assets/icons/delete.png"
          alt=""
        >
      </div>
      <p
        class="text-description mon-regular mt-2"
        v-html="texts.rejectorder.description"
      />
      <div class="display-flex align-items-center mt-9">
        <v-btn
          class="button-secondary mon-regular"
          elevation="0"
          style="width: 45%;"
          @click="closeDialog"
        >
          {{ texts.rejectorder.buttons.cancel }}
        </v-btn>
        <v-spacer />
        <v-btn
          class="button-primary mon-medium"
          elevation="0"
          style="width: 45%;"
          :loading="bLoading"
          @click="rejectOrder"
        >
          {{ texts.rejectorder.buttons.confirm }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "OrderDetailRejectOrderLayout",
  data() {
    return {
      texts: "",
      screenWidth: 0,
      bLoading: false,
    };
  },
  beforeMount() {
    this.texts = FILE.rejectorder[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    rejectOrder: function () {
      this.bLoading = true;

      const payload = {
        sDescription: "N/A",
        sPurchaseOrderStatusId: "38b51cdd-95ca-4870-acde-68ed35597adb", //cancelado
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/status/purchase-orders/${this.purchaseOrderId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.closeDialog();
          this.$router.push("/orders");
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    closeDialog: function () {
      this.$store.commit("setDialogRejectOrder", {
        active: false,
        purchaseOrderId: "",
        idSalesOrder: "",
      });
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 35;
      } else {
        this.screenWidth = 100;
      }
    },
  },
  computed: {
    // return create contact dialog state
    active() {
      return this.$store.state.rejectOrderActive;
    },
    purchaseOrderId: function () {
      return this.$store.state.rejectPurchaseOrderId;
    },
    salesOrderId: function () {
      return this.$store.state.rejectSalesOrderId;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    active() {
      if (this.active) {
        this.texts = FILE.rejectorder[this.selectLanguage];
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.rejectorder[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 20px;
}

.text-description {
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}
</style>